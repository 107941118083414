


































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetEmpresas,
  dispatchUpdateEmpresa,
} from '@/store/empresa/actions';
import { consultarEmpresa } from '@/store/empresa/getters';
import { IEmpresaUpdate } from '@/interfaces/empresa';

@Component
export default class AlterarEmpresa extends Vue {
  public valid = true;
  public nome: string = '';

  public async mounted() {
    await dispatchGetEmpresas(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.empresa) {
      this.nome = this.empresa.nome;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const empresaAtualizada: IEmpresaUpdate = {
        nome: this.nome,
      };

      const atualizou = await dispatchUpdateEmpresa(this.$store, {
        id: this.empresa!.id,
        empresa: empresaAtualizada,
      });
      if (atualizou) {
        this.$router.push('/main/empresas/listar');
      }
    }
  }

  get empresa() {
    return consultarEmpresa(this.$store)(+this.$router.currentRoute.params.id);
  }
}
